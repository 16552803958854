import React from "react";
import './App.css';
import HttpTrafficChart from './components/HttpTrafficChart';
import PopularDomainsChart from "./components/PopularDomainsChart";
import Attack3LayerChart from "./components/Attack3LayerChart";

function App() {

    const [selectedTab, setSelectedTab] = React.useState(1);

    const renderTab = () => {
        if (selectedTab === 1) {
            return <HttpTrafficChart />
        }
        else if (selectedTab === 2) {
            return <PopularDomainsChart />
        }
        else if (selectedTab === 3) {
            return <Attack3LayerChart />
        }
    }

  return (
    <div className="App">
        <header className="appHeader">
            <div className="headerTab" onClick={() => setSelectedTab(1)}>Traffic Change</div>
            <div className="headerTab" onClick={() => setSelectedTab(2)}>Popular Domains</div>
            <div className="headerTab" onClick={() => setSelectedTab(3)}>Layer 3 DDoS Attack</div>
        </header>
        <div className="appBody">
            {renderTab()}
        </div>
    </div>
  );
}

export default App;
