import React, {useEffect, useState} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import './HttpTrafficChart.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

const endpoint = "traffic-change"
const fetchApiData = async (setChartData) => {
    const response = await fetch('https://report-worker.kkumar-general.workers.dev/' + endpoint)
    const data = await response.json()
    const { timestamps, values  } = data.data['http'];
    const totalObject = data.data['total'];
    const totalValues = totalObject['values'];
    const totalTimestamps = totalObject['timestamps'];
    setChartData({
        httpTimestamps: timestamps,
        httpValues: values,
        totalTimestamps: totalTimestamps,
        totalValues: totalValues,
    })
}

export const getData = (chartData) => ({
    labels: chartData.httpTimestamps.map((val) => {
        const date = new Date(val);
        return month[date.getMonth()] + " " + date.getDate();
    }),
    datasets: [
        {
            label: 'HTTP Traffic',
            data: chartData.httpValues,
            borderColor: '#404041',
            backgroundColor: '#404041',
            },
        {
            label: 'Total Traffic',
            data: chartData.totalValues,
            borderColor: '#FAAD3F',
            backgroundColor: '#FAAD3F',
        },
    ],
});

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Total Traffic Change vs HTTP Traffic Change (Last 30 days)',
        },
    },
};

const HttpTrafficChart = () => {

    const [chartData, setChartData] = useState({
        httpTimestamps: [],
        httpValues: [],
        totalTimestamps: [],
        totalValues: [],
    });

    useEffect(() => {
        fetchApiData(setChartData).catch(console.error)
    }, [])

    return (
        <div className="chartContainer">
            <Line options={options} data={getData(chartData)} />
        </div>
    )
}
export default HttpTrafficChart;