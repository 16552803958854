import React, {useEffect, useState} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import './PopularDomainChart.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

const endpoint = "popular-domains"
const fetchApiData = async (setDomainData) => {
    const response = await fetch('https://report-worker.kkumar-general.workers.dev/' + endpoint)
    const data = await response.json()
    setDomainData(data['rankingEntries'])
    // setChartData(data);
}

export const getData = (chartData) => ({
    labels: chartData.httpTimestamps.map((val) => {
        const date = new Date(val);
        return month[date.getMonth()] + " " + date.getDate();
    }),
    datasets: [
        {
            label: 'HTTP Traffic',
            data: chartData.httpValues,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Total Traffic',
            data: chartData.totalValues,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
});

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Total Traffic vs HTTP Traffic',
        },
    },
};

const PopularDomainsChart = () => {

    const [domainData, setDomainData] = useState([]);

    const renderArrowComponent = (rankChange) => {
        if (rankChange === 0) {
            return ""
        }
        else if (rankChange > 0) {
            return <img src="./up-arrow.png" alt="Up arrow"/>
        }
        else {
            return <img src="./down-arrow.png" alt="Down Arrow"/>
        }
    }

    useEffect(() => {
        fetchApiData(setDomainData).catch(console.error)
    }, [])

    return (
        <div className="chartContainer">
            <table className="domainTable">
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Domain</th>
                        <th>Rank Change</th>
                    </tr>
                </thead>
                <tbody>
                    {domainData.map(({ rank, rankChange, domain }) => (
                            <tr key={rank+1}>
                                <td>{rank+1}</td>
                                <td>{domain}</td>
                                <td className="rankChangeContainer">
                                    <div>{rankChange}</div>
                                    {renderArrowComponent(rankChange)}
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
        </div>
    )
}
export default PopularDomainsChart;