import React, {useEffect, useState} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import './HttpTrafficChart.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

const endpoint = "attack-layer3"
const fetchApiData = async (setChartData) => {
    const response = await fetch('https://report-worker.kkumar-general.workers.dev/' + endpoint)
    const data = await response.json()
    const attackData = data.data;
    const timestamps = attackData['total'].timestamps;
    const attackValues = attackData['total'].values;
    // const sum = totalData.reduce((a, b) => a + b, 0);
    // console.log(sum);
    const percentages = attackValues.map((val, index) => {
        // return (totalData[index]/sum) * 100;
        return attackValues[index] * 100;
    });

    setChartData({
        timestamps: timestamps,
        percentages: percentages,
    });
}

export const getData = (chartData) => ({
    labels: chartData.timestamps.map((val) => {
        const date = new Date(val);
        const time = date.toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
        });
        return month[date.getMonth()] + " " + date.getDate() + " - " + time;
    }),
    datasets: [
        {
            label: 'Percentage of DDoS Attack (Last 30 days)',
            data: chartData.percentages,
            borderColor: '#FAAD3F',
            backgroundColor: '#FAAD3F',
        },
    ],
});

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Layer 3 DDoS Attack (%) for Last 30 days',
        },
    },
};

const Attack3LayerChart = () => {

    const [chartData, setChartData] = useState({
        timestamps: [],
        percentages: [],
    });

    useEffect(() => {
        fetchApiData(setChartData).catch(console.error)
    }, [])

    return (
        <div className="chartContainer">
            <Line options={options} data={getData(chartData)} />
        </div>
    )
}

export default Attack3LayerChart;